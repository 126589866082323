<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc"></p>
            <!-- skeleton  -->

            <form class="needs-validation" @submit.prevent="formWage">
              <div class="row">
                <div class="col-md-3 col-sm-6">
                  <div class="mb-3 position-relative">
                    <label for="wageCode">รหัสค่าแรง</label>
                    <input
                      id="wageCode"
                      v-model="form.wageCode"
                      type="text"
                      class="form-control"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.form.wageCode.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.form.wageCode.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.form.wageCode.maxLength">{{
                        message
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6">
                  <div class="mb-3 position-relative">
                    <code>* </code
                    ><label for="nameTh">ชื่อค่าแรง (ภาษาไทย)</label>
                    <input
                      id="nameTh"
                      v-model="form.nameTh"
                      type="text"
                      class="form-control"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.form.nameTh.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.form.nameTh.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.form.nameTh.required">{{ message }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-6">
                  <div class="mb-3 position-relative">
                    <label for="nameEn">ชื่อค่าแรง (ภาษาอังกฤษ)</label>
                    <input
                      id="nameEn"
                      v-model="form.nameEn"
                      type="text"
                      class="form-control"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.form.nameEn.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.form.nameEn.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.form.nameEn.required">{{ message }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-6 col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="maxDcPer">ส่วนลดสูงสุด(%)</label>
                    <div class="input-group">
                      <input
                        id="maxDcPer"
                        v-model="form.maxDcPer"
                        min="0"
                        max="100"
                        class="form-control"
                        placeholder=""
                        :class="{
                          'is-invalid': submitform && $v.form.maxDcPer.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.maxDcPer.$error"
                        class="invalid-tooltip"
                      >
                        <span
                          v-if="
                            !$v.form.maxDcPer.maxValue ||
                            !$v.form.maxDcPer.minValue
                          "
                          >{{ message }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="maxDcAmount">ส่วนลดสูงสุด(บาท)</label>
                    <div class="input-group">
                      <input
                        :min="1"
                        id="maxDcAmount"
                        v-model="form.maxDcAmount"
                        class="form-control"
                        placeholder=""
                        :class="{
                          'is-invalid':
                            submitform && $v.form.maxDcAmount.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.maxDcAmount.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.maxDcAmount.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <code>* </code><label for="wageType">ประเภทค่าแรง</label>
                    <multiselect
                      id="wageType"
                      v-model="form.wageType"
                      :options="optionsWage"
                      :class="{
                        'is-invalid': submitform && $v.form.wageType.$error,
                      }"
                      track-by="name"
                      label="name"
                      :show-labels="false"
                    ></multiselect>

                    <div
                      v-if="submitform && $v.form.wageType.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.form.wageType.required">{{
                        message
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="price">ค่าแรงต่อชั่วโมง(ครั้ง)</label>
                    <div class="input-group">
                      <input
                        :disabled="form.wageType.id === 'FRE'"
                        :precision="2"
                        min="0.01"
                        step="0.01"
                        id="price"
                        v-model="form.price"
                        class="form-control"
                        placeholder=""
                        :class="{
                          'is-invalid': submitform && $v.form.price.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.price.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.price.numeric">{{ err }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="workDuration">ระยะเวลาทำงาน(ชั่วโมง)</label>
                    <div class="input-group">
                      <input
                        :disabled="
                          form.wageType.id === 'FRE' ||
                          form.wageType.id === 'PX'
                        "
                        :min="0"
                        id="workDuration"
                        v-model="form.workDuration"
                        type="text"
                        class="form-control"
                        placeholder=""
                        :class="{
                          'is-invalid':
                            submitform && $v.form.workDuration.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.workDuration.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.workDuration.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md">
                    <b-form-checkbox
                      v-model="form.useInBp"
                      value="1"
                      unchecked-value="0"
                      class="mb-3"
                      checked
                      plain
                      >ใช้กับศูนย์บริการ</b-form-checkbox
                    >
                    <b-form-checkbox
                      v-model="form.useInGs"
                      value="1"
                      unchecked-value="0"
                      class="mb-3"
                      checked
                      plain
                      >ใช้กับศูนย์ซ่อมสีและตัวถัง</b-form-checkbox
                    >
                  </div>
                </div>
              </div>
              <br />

              <div class="col-md">
                <button class="btn btn-success float-end" type="submit">
                  บันทึก
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>

    <!-- end col -->
  </Layout>
</template>
<script>
import {
  required,
  // numeric,
  // email,
  // minLength,
  // sameAs,
  maxLength,
  minValue,
  maxValue,
  // url,
  // alphaNum
} from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Multiselect from "vue-multiselect";

/**
 * Form validation component
 */
export default {
  mounted() {},
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  page: {
    title: "Wages",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      loading: undefined,
      err: "โปรดกรอกข้อมูลให้ถูกต้อง",
      message: "โปรดตรวจสอบข้อมูล",
      title: "เพิ่มข้อมูลค่าแรง",
      items: [
        {
          text: "ข้อมูลค่าแรง",
          href: "/wages/master-wages",
        },
        {
          text: "เพิ่มข้อมูลค่าแรง",
          active: true,
        },
      ],

      optionsBranch: [
        {
          name: "สุริยะไอที สำนักงานใหญ่",
          id: "1",
        },
      ],
      optionsWage: [
        {
          name: "HR",
          id: "HR",
        },
        {
          name: "PX",
          id: "PX",
        },
        {
          name: "FRE",
          id: "FRE",
        },
      ],

      options: [],
      form: {
        nameTh: "",
        nameEn: "",
        price: "",
        wageType: "",
        wageCode: "",
        maxDcPer: "",
        maxDcAmount: "",
        useInBp: "",
        useInGs: "",
        workDuration: "",
      },

      submitform: false,
    };
  },

  validations: {
    form: {
      nameTh: {
        required,
      },
      nameEn: {},
      price: {},
      wageType: {
        required,
        maxLength: maxLength(100),
      },
      wageCode: {
        maxLength: maxLength(20),
      },
      maxDcPer: {
        minValue: minValue(0),
        maxValue: maxValue(100),
      },
      maxDcAmount: {},
      useInBp: {},
      useInGs: {},

      workDuration: {},
    },
  },
  created() {
    this.getLocalData();
  },
  methods: {
    customLabel({ id, first_name }) {
      return `${id}  ${first_name}`;
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;

      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item);
      });
      this.localDataBranch = localDataBranch;

      // console.log(this.localDataBranch);
    },
    // eslint-disable-next-line no-unused-vars
    formWage() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.form.$invalid != true) {
        this.postWage();
      }
    },

    postWage: function () {
      this.overlayFlag = true;
      const bodyFormData = new FormData();

      // // parse

      bodyFormData.append(
        "nameTh",
        this.form.nameTh != null ? this.form.nameTh : ""
      );
      bodyFormData.append(
        "nameEn",
        this.form.nameEn != null ? this.form.nameEn : ""
      );
      bodyFormData.append(
        "price",
        this.form.price != null ? this.form.price : ""
      );
      bodyFormData.append(
        "wageType",
        this.form.wageType.id != null ? this.form.wageType.id : ""
      );
      bodyFormData.append(
        "wageCode",
        this.form.wageCode != null ? this.form.wageCode : ""
      );
      bodyFormData.append(
        "maxDcPer",
        this.form.maxDcPer != null ? this.form.maxDcPer : ""
      );
      bodyFormData.append(
        "maxDcAmount",
        this.form.maxDcAmount != null ? this.form.maxDcAmount : ""
      );
      bodyFormData.append(
        "useInBp",
        this.form.useInBp != null ? this.form.useInBp : "0"
      );
      bodyFormData.append(
        "useInGs",
        this.form.useInGs != null ? this.form.useInGs : "0"
      );
      bodyFormData.append(
        "workDuration",
        this.form.workDuration != null ? this.form.workDuration : ""
      );

      useNetw
        .post("api/central/wage/store", bodyFormData)
        .then((response) => {
          // console.log(response);
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({ name: "master-wages" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
